<template>
  <div>
    <ConfirmationModal
      :title="$t('labels.warning')"
      :message="$t('description.deleteModalDescription')"
      :confirm="deleteCompany"
      v-model="deleteCompanyConfirmationModal"
    />
    <v-card elevation="1">
      <v-card-title style="color: var(--darkBlue)">
        <v-row>
          <v-col class="text-lg-left text-center" lg="5" cols="12">
            <h3>{{ $t('navigationDrawerMenu.portalManagement') }}</h3>
          </v-col>
        </v-row>
        <v-btn
          @click="activePortals"
          class="gradient-button button px-5 mr-4"
          dark
        >
          <v-icon small class="mr-2" style="font-size: 24px">
            mdi-package-up
          </v-icon>
          {{
            $vuetify.breakpoint.xs
              ? $t('buttons.lumpActivateMobile')
              : $t('buttons.lumpActivate', {
                  value: selectedPortals.length,
                })
          }}
        </v-btn>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-2"
              style="font-size: 32px"
              v-bind="attrs"
              v-on="on"
              @click="mergePortalUserDialog = true"
            >
              mdi-merge
            </v-icon>
          </template>
          <span>{{ $t('buttons.merge') }}</span>
        </v-tooltip>

        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-icon
              small
              class="mr-3"
              style="font-size: 32px"
              v-bind="attrs"
              v-on="on"
              @click="addPortalUserDialog = true"
            >
              mdi-plus-box-outline
            </v-icon>
          </template>
          <span>{{ $t('buttons.add') }}</span>
        </v-tooltip>

        <RefreshButton :refreshFunction="getPortalUsers"></RefreshButton>
      </v-card-title>

      <v-divider />
      <v-card-text fill-height fluid>
        <v-row align="center" justify="start" class="d-flex">
          <v-col cols="12" class="align-center">
            <v-row class="align-center pt-3 pb-5">
              <FormElement
                v-model="companyName"
                :validationLabel="$t('labels.companyName')"
                :formLabel="$t('labels.companyName')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="4"
                :sm="3"
                :md="3"
                :lg="3"
                :xl="3"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.companyName')"
                :classes="'pb-2 pb-sm-0'"
              />
              <FormElement
                v-model="portalCustomerCode"
                :validationLabel="$t('labels.portalCustomerCode')"
                :formLabel="$t('labels.portalCustomerCode')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="4"
                :sm="3"
                :md="3"
                :lg="3"
                :xl="3"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.portalCustomerCode')"
                :classes="'pb-2 pb-sm-0'"
              />
              <FormElement
                v-model="portalSellerCode"
                :validationLabel="$t('labels.portalSellerCode')"
                :formLabel="$t('labels.portalSellerCode')"
                @input="getSearchDebounced"
                :formType="{
                  name: 'text',
                  dense: true,
                  appendIcon: 'mdi-magnify',
                  appendClick: () => {
                    getSearchDebounced();
                  },
                }"
                :labelShown="false"
                :cols="4"
                :sm="3"
                :md="3"
                :lg="3"
                :xl="3"
                :topLabel="true"
                :hideDetails="true"
                :placeholder="$t('labels.portalSellerCode')"
                :classes="'pb-2 pb-sm-0'"
              />
              <v-col
                :cols="4"
                :sm="3"
                :md="3"
                :lg="3"
                :xl="3"
                class="py-0 pl-1 pr-1 pb-2 pb-sm-0"
              >
                <v-autocomplete
                  class="custom-border"
                  v-model="portalCustomerType"
                  :value="portalCustomerType"
                  :items="customerTypes"
                  :label="$t('labels.customerType')"
                  item-text="Name"
                  item-value="Value"
                  @change="getSearchDebounced"
                  clearable
                  :return-object="true"
                  dense
                  hide-details
                  variant="solo"
                  outlined
                >
                </v-autocomplete>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
        <v-data-table
          :headers="headers"
          :items="users"
          :options="{
            itemsPerPage: options.itemsPerPage,
          }"
          style="padding: 0 16px"
          hide-default-footer
          :loading="$store.state.loadingStatus"
          :no-data-text="$t('messages.noData')"
          :item-class="setRowClass"
          ><!-- eslint-disable -->
          <template v-slot:item.Checkbox="{ item, select, isSelected }">
            <v-checkbox
              :disabled="
                item.PackageLoginPermission ||
                (selectedPortals.length >= maxSelection &&
                  selectedPortals.includes(item) == false)
              "
              @change="updateSelection(item)"
              large
              color="green"
              :off-icon="'mdi-checkbox-blank-outline'"
              :on-icon="'mdi-checkbox-marked-outline'"
            ></v-checkbox>
          </template>
          <template v-slot:item.PhotoUrl="{ item }">
            <img
              :src="item.PhotoUrl"
              :alt="item.Title"
              style="width: 50px; height: 50px; object-fit: contain"
            />
          </template>
          <template v-slot:item.CreatedOnUtc="{ item }">
            {{ convertToLocal(item.CreatedOnUtc) }}
          </template>
          <template v-slot:item.Country="{ item }">
            {{ item.Country + ' ' + item.City }}
          </template>
          <template v-slot:item.PortalCustomerType="{ item }">
            <v-chip
              v-if="item.PortalCustomerType == 0"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.tooltipCorporate') }}
            </v-chip>

            <v-chip
              v-if="item.PortalCustomerType == 1"
              class="ma-2 text--center"
              small
              color="#e3e2e3"
              text-color="#2b3838"
            >
              {{ $t('labels.tooltipMarketPlace') }}
            </v-chip>
          </template>
          <template v-slot:item.StatusType="{ item }">
            <v-chip
              v-if="item.StatusType == 1"
              class="ma-2"
              small
              color="#c5f4db"
              text-color="#2b3838"
            >
              {{ $t('labels.tooltipActive') }}
            </v-chip>

            <v-chip
              v-if="item.StatusType == 2"
              class="ma-2 text--center"
              small
              color="#e3e2e3"
              text-color="#2b3838"
            >
              {{ $t('labels.tooltipPassive') }}
            </v-chip>
          </template>
          <template v-slot:item.actions="{ item }">
            <div class="d-flex">
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :disabled="item.PackageLoginPermission == false"
                    @click="edit(item)"
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                  >
                    mdi-pencil
                  </v-icon>
                </template>
                <span>{{ $t('labels.tooltipEdit') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :disabled="item.PackageLoginPermission == false"
                    small
                    color="#29B7B7"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="loginAs(item)"
                  >
                    mdi-login-variant
                  </v-icon>
                </template>
                <span>{{ $t('labels.loginAs') }}</span>
              </v-tooltip>
              <v-tooltip top>
                <template v-slot:activator="{ on, attrs }">
                  <v-icon
                    :disabled="item.PackageLoginPermission == false"
                    small
                    color="red"
                    class="mr-1"
                    style="font-size: 20px"
                    v-bind="attrs"
                    v-on="on"
                    @click="
                      deleteCompanyConfirmationModal = true;
                      selectedCompany = item.Id;
                    "
                  >
                    mdi-trash-can
                  </v-icon>
                </template>
                <span>{{ $t('labels.deleteKey') }}</span>
              </v-tooltip>
            </div>
          </template>
        </v-data-table>
        <div class="text-xs-center pt-2 md4 lg4">
          <v-row align="center">
            <v-col md="3" class="ml-md-auto">
              <v-pagination
                @input="paginationChangeHandler"
                :value="page"
                :length="pages"
                :total-visible="10"
                circle
              ></v-pagination>
            </v-col>
            <v-col md="2" class="ml-md-auto">
              <v-select
                v-model="rowsPerPage"
                :items="perPageValues"
                :label="$t('labels.selectItemsPerPage')"
                item-value="value"
                item-text="name"
                outlined
                dense
                @change="changeItemsPerPage()"
                rounded
              >
              </v-select>
            </v-col>
          </v-row>
        </div>

        <v-dialog
          v-model="addPortalUserDialog"
          @input="resetDialog"
          @close="resetDialog"
          max-width="800"
        >
          <v-card class="popup-card" elevation="1">
            <v-card-title
              class="popupHeadline d-flex justify-center"
              style="font-size: 16px"
            >
              {{ $t('labels.addPortalUser') }}
            </v-card-title>

            <v-divider></v-divider>
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
              <v-card-text class="mt-2">
                <form @submit.prevent="handleSubmit(addPortalUser)">
                  <v-row>
                    <FormElement
                      v-for="(element, index) in formElement"
                      :key="
                        element.key.parent +
                        '_' +
                        element.key.child +
                        '_' +
                        index
                      "
                      v-model="$data[element.key.parent][element.key.child]"
                      :validationLabel="$t(element.validationLabel)"
                      :rules="element.rules"
                      :formLabel="$t(element.formLabel)"
                      :onChange="element.onChange"
                      :formType="element.formType"
                      :cols="element.cols"
                      :sm="element.sm"
                      :md="element.md"
                      :lg="element.lg"
                      :xl="element.xl"
                      :hidden="element.hidden"
                      :labelShown="element.labelShown"
                      :topLabel="element.topLabel"
                      :active="element.active"
                      :classes="element.classes"
                      :placeholder="$t(element.placeholder)"
                      :willBeEliminated="element.willBeEliminated"
                    />
                  </v-row>

                  <v-card-actions class="d-flex justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;
                        padding: 2vh 3vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      color="primary"
                      :loading="btnLoading"
                      >{{ $t('buttons.add') }}</v-btn
                    >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--red);
                        padding: 2vh 2vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      @click="resetDialog"
                      :loading="btnLoading"
                      >{{ $t('buttons.cancel') }}</v-btn
                    >
                  </v-card-actions>
                </form>
              </v-card-text>
            </ValidationObserver>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="mergePortalUserDialog"
          @input="resetMergeDialog"
          @close="resetMergeDialog"
          max-width="500"
        >
          <v-card class="popup-card" elevation="1">
            <v-card-title
              class="popupHeadline d-flex justify-center"
              style="font-size: 16px"
            >
              {{ $t('labels.addPortalUser') }}
            </v-card-title>

            <v-divider></v-divider>
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
              <v-card-text class="mt-2">
                <form @submit.prevent="handleSubmit(mergePortalUser)">
                  <v-row>
                    <FormElement
                      v-model="mail"
                      :lg="12"
                      :rules="'required|email'"
                      :validationLabel="$t('labels.email')"
                      :labelShown="false"
                      :placeholder="$t('labels.email')"
                      :formType="{ name: 'text' }"
                    />
                  </v-row>

                  <v-card-actions class="d-flex justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;
                        padding: 2vh 3vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      color="primary"
                      >{{ $t('buttons.add') }}</v-btn
                    >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--red);
                        padding: 2vh 2vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      @click="resetMergeDialog"
                      >{{ $t('buttons.cancel') }}</v-btn
                    >
                  </v-card-actions>
                </form>
              </v-card-text>
            </ValidationObserver>
          </v-card>
        </v-dialog>

        <v-dialog
          v-model="editModal"
          @input="resetEditModal"
          @close="resetEditModal"
          max-width="800"
        >
          <v-card class="popup-card" elevation="1">
            <v-card-title
              class="popupHeadline d-flex justify-center"
              style="font-size: 16px"
            >
              {{ $t('labels.editPortalUser') }}
            </v-card-title>

            <v-divider></v-divider>
            <ValidationObserver v-slot="{ handleSubmit }" ref="observer">
              <v-card-text class="mt-2">
                <form @submit.prevent="handleSubmit(updatePortalUser)">
                  <v-row>
                    <FormElement
                      v-for="(element, index) in editFormElement"
                      :key="
                        element.key.parent +
                        '_' +
                        element.key.child +
                        '_' +
                        index
                      "
                      v-model="$data[element.key.parent][element.key.child]"
                      :validationLabel="$t(element.validationLabel)"
                      :rules="element.rules"
                      :formLabel="$t(element.formLabel)"
                      :onChange="element.onChange"
                      :formType="element.formType"
                      :cols="element.cols"
                      :sm="element.sm"
                      :md="element.md"
                      :lg="element.lg"
                      :xl="element.xl"
                      :hidden="element.hidden"
                      :labelShown="element.labelShown"
                      :topLabel="element.topLabel"
                      :active="element.active"
                      :classes="element.classes"
                      :placeholder="$t(element.placeholder)"
                    />
                  </v-row>

                  <v-card-actions class="d-flex justify-center">
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: #0077d5;
                        padding: 2vh 3vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      type="submit"
                      color="primary"
                      >{{ $t('buttons.update') }}</v-btn
                    >
                    <v-btn
                      style="
                        color: #fff;
                        text-transform: unset !important;
                        background-color: var(--red);
                        padding: 2vh 2vh;
                        border-radius: 30px !important;
                        font-weight: bolder;
                        font-size: medium;
                      "
                      elevation="0"
                      class="darken-1"
                      @click="resetEditModal"
                      >{{ $t('buttons.cancel') }}</v-btn
                    >
                  </v-card-actions>
                </form>
              </v-card-text>
            </ValidationObserver>
          </v-card>
        </v-dialog>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import RefreshButton from '../components/RefreshButton.vue';
import FormElement from '../components/FormElement.vue';
import PortalService from '../services/PortalService';
import AddressService from '../services/AddressService';
import AccountService from '../services/AccountService';
import CompanyServices from '../services/CompanyServices';
import { mapActions } from 'vuex';
import ConfirmationModal from '../components/General/ConfirmationModal.vue';
import { clearText } from '../utils/constants';
import rules from '../utils/validationRules';
import CompanyPackageService from '../services/CompanyPackageService';

export default {
  data: () => ({
    btnLoading: false,
    showPassword: true,
    showConfirmPassword: false,
    users: [],
    form: {
      AgencyId: null,
      PaymetTime: 0,
      CompanyCategory: 1,
    },
    countries: [],
    cities: [],
    customerTypes: [],
    addPortalUserDialog: false,
    mergePortalUserDialog: false,
    headers: [],
    itemsPerPageOptions: [],
    totalItems: 0,
    paymentTargets: [],
    mail: null,
    agencies: [],
    selectedPortals: [],
    maxSelection: 0,
    options: {
      itemsPerPage: 15,
    },
    rowsPerPage: 15,
    page: 1,
    perPageValues: [
      {
        name: '5',
        value: 5,
      },
      {
        name: '10',
        value: 10,
      },
      {
        name: '15',
        value: 15,
      },
      {
        name: '30',
        value: 30,
      },
      {
        name: '50',
        value: 50,
      },
      {
        name: '100',
        value: 100,
      },
    ],

    paymentTypes: [],
    companyCategories: [],
    deleteCompanyConfirmationModal: false,
    selectedCompany: '',

    payingTypes: [],
    temporaryReceiptAddress: '',
    receiptStatus: false,
    companyName: '',
    portalCustomerCode: '',
    portalSellerCode: '',
    portalCustomerType: null,

    editItem: {
      CompanyCategory: 1,
    },
    editModal: false,
  }),
  mounted() {
    this.form.AddCarrierLogoToLabel = true;
    this.payingTypes = [
      {
        name: this.$t('labels.transfer'),
        value: 1,
      },
      {
        name: this.$t('labels.creditCardOrder'),
        value: 2,
      },
    ];
    this.paymentTypes = [
      {
        name: this.$t('labels.upFront'),
        value: 0,
      },
      {
        name: this.$t('labels.deferredPayment'),
        value: 1,
      },
    ];

    this.companyCategories = [
      {
        name: this.$t('labels.customer'),
        value: 1,
      },
      {
        name: this.$t('labels.intermediateAgency'),
        value: 2,
      },
    ];

    this.headers = [
      {
        value: 'Checkbox',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.companyName'),
        value: 'CompanyName',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.portalCustomerCode'),
        value: 'PortalCustomerCode',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.authorizedPerson'),
        value: 'PortalOwnerName',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.portalSellerCode'),
        value: 'PortalSellerCode',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.email'),
        value: 'Email',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.country') + '/' + this.$t('labels.city'),
        value: 'Country',
        sortable: false,
        align: 'center',
        width: '20%',
      },
      {
        text: this.$t('labels.userPhone'),
        value: 'GsmNo',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.customerType'),
        value: 'PortalCustomerType',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.recordDate'),
        value: 'CreatedOnUtc',
        sortable: false,
        align: 'center',
      },
      {
        text: this.$t('labels.actions'),
        value: 'actions',
        sortable: false,
        align: 'center',
      },
    ];
    this.paymentTargets = [
      {
        Id: 0,
        Name: this.$t('labels.applicationName'),
      },
      {
        Id: 1,
        Name: this.$t('labels.supplier'),
      },
    ];
    this.customerTypes = [
      {
        Id: 0,
        Name: this.$t('labels.tooltipCorporate'),
      },
      {
        Id: 1,
        Name: this.$t('labels.tooltipMarketPlace'),
      },
    ];
    this.getCountries();
    this.getPortalUsers();
    this.fillAgencies();
  },
  components: {
    RefreshButton,
    FormElement,
    ConfirmationModal,
  },
  computed: {
    isItemSelected() {
      return (item) => this.selectedPortals.includes(item);
    },
    pages() {
      if (this.rowsPerPage == null || this.totalItems == null) {
        return 0;
      }
      return Math.ceil(this.totalItems / this.rowsPerPage);
    },
    formElement() {
      return [
        {
          key: {
            parent: 'form',
            child: 'Name',
          },
          rules: 'required',
          validationLabel: 'labels.name',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.name',
        },
        {
          key: {
            parent: 'form',
            child: 'Surname',
          },
          rules: 'required',
          validationLabel: 'labels.user.lastName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.user.lastName',
        },
        {
          key: {
            parent: 'form',
            child: 'Email',
          },
          validationLabel: 'labels.userEmail',
          topLabel: true,
          labelShown: false,
          rules: 'required|email',
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.userEmail',
        },
        {
          key: {
            parent: 'form',
            child: 'PhoneNumber',
          },
          rules: rules.phone,
          validationLabel: 'labels.phone',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.phone',
        },
        {
          key: {
            parent: 'form',
            child: 'CompanyName',
          },
          rules: 'required',
          validationLabel: 'labels.companyName',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.companyName',
        },
        {
          key: {
            parent: 'form',
            child: 'Password',
          },
          rules: 'required|max:24',
          rules: {
            required: true,
            max: 24,
            min: 6,
            regex:
              /^(?=.*\d)(?=.*[!@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?])(?=.*[a-z])(?=.*[A-Z]).{6,}$/,
          },
          validationLabel: 'labels.password',
          topLabel: true,
          labelShown: false,
          formLabel: 'labels.password',
          formType: {
            name: this.showPassword ? 'seePassword' : 'password',
            counter: '24',
            appendIcon: this.showPassword ? 'mdi-eye' : 'mdi-eye-off',
            appendOuterIcon: 'mdi-key-plus',
            appendClick: () => {
              this.showPassword = !this.showPassword;
            },
            appendOuterClick: () => {
              this.generatePassword();
            },
            appendOuterText: this.$t('labels.generatePassword'),
            autocomplete: 'new-password',
          },
          lg: 12,
          hideDetails: true,
          placeholder: 'labels.password',
          disabled: true,
        },
        {
          key: {
            parent: 'form',
            child: 'PortalCustomerCode',
          },

          validationLabel: 'labels.portalCustomerCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          placeholder: 'labels.portalCustomerCode',
        },
        {
          key: {
            parent: 'form',
            child: 'PortalSellerCode',
          },

          validationLabel: 'labels.portalSellerCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          placeholder: 'labels.portalSellerCode',
        },

        // {
        //   key: {
        //     parent: 'form',
        //     child: 'confirmPassword',
        //   },
        //   rules:
        //     'required|max:24|confirmedPassword:' + this.$t('labels.password'),
        //   validationLabel: 'labels.userPasswordAgain',
        //   topLabel: true,
        //   labelShown: false,
        //   formLabel: 'labels.userPasswordAgain',
        //   formType: {
        //     name: this.showConfirmPassword ? 'text' : 're-password',
        //     appendIcon: this.showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off',
        //     appendClick: () => {
        //       this.showConfirmPassword = !this.showConfirmPassword;
        //     },
        //   },
        //   lg: 6,
        //   placeholder: 'labels.userPasswordAgain',
        // },
        {
          key: {
            parent: 'form',
            child: 'TaxOffice',
          },
          rules: 'required',
          validationLabel: 'labels.company.taxOffice',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          placeholder: 'labels.company.taxOffice',
        },
        {
          key: {
            parent: 'form',
            child: 'TaxNo',
          },
          rules: 'required',
          validationLabel: 'labels.company.taxNo',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          cols: 12,
          sm: 6,
          placeholder: 'labels.company.taxNo',
        },
        {
          key: {
            parent: 'form',
            child: 'PortalCustomerType',
          },
          rules: 'required',
          validationLabel: 'labels.customerType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.customerTypes,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          placeholder: 'labels.customerType',
        },
        {
          key: {
            parent: 'form',
            child: 'Country',
          },
          rules: 'required',
          validationLabel: 'labels.country',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.countries,
              value: 'Id',
              text: 'Name',
            },
          },
          onChange: async () => {
            this.onChangeCountry();
          },
          lg: 6,
          placeholder: 'labels.country',
        },
        {
          key: {
            parent: 'form',
            child: 'City',
          },
          rules: 'required',
          validationLabel: 'labels.city',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.cities,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          placeholder: 'labels.city',
        },
        {
          key: {
            parent: 'form',
            child: 'District',
          },
          rules: 'required',
          validationLabel: 'labels.district',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.district',
        },
        {
          key: {
            parent: 'form',
            child: 'Postcode',
          },
          rules: 'required|min:4|max:9',
          validationLabel: 'labels.postalCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.postalCode',
        },
        {
          key: {
            parent: 'form',
            child: 'Address1',
          },
          rules: 'required',
          validationLabel: 'labels.address',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.address',
        },
        {
          key: {
            parent: 'form',
            child: 'ReceiptAddress',
          },
          rules: this.form.receiptStatus ? '' : 'required',
          hidden: this.form.receiptStatus,
          active: !this.form.receiptStatus,
          validationLabel: 'labels.receiptAddress',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'textarea',
          },
          lg: 12,
          cols: 12,
          placeholder: 'labels.receiptAddress',
        },
        {
          key: {
            parent: 'form',
            child: 'receiptStatus',
          },
          validationLabel: 'labels.receiptAddressInfo',
          topLabel: true,
          light: false,
          labelShown: false,
          classes: 'mb-5',
          formType: {
            name: 'checkbox',
          },
          onChange: () => {
            this.clickedReceiptStatus();
          },
          lg: 12,
          placeholder: 'labels.receiptAddressInfo',
        },
        {
          key: {
            parent: 'form',
            child: 'PaymentTarget',
          },
          rules: 'required',
          validationLabel: 'labels.paymentTarget',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.paymentTargets,
              value: 'Id',
              text: 'Name',
            },
          },
          cols: 12,
          sm: 4,
          md: 4,
          lg: 4,
          placeholder: 'labels.paymentTarget',
        },
        {
          key: {
            parent: 'form',
            child: 'PaymetTime',
          },
          rules: 'required',
          validationLabel: 'labels.payingType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.paymentTypes,
              value: 'value',
              text: 'name',
            },
          },
          cols: 12,
          sm: 4,
          md: 4,
          lg: 4,
          placeholder: 'labels.payingType',
        },
        {
          key: {
            parent: 'form',
            child: 'PaymentType',
          },
          validationLabel: 'labels.paymentType',
          formLabel: 'labels.paymentType',
          formType: {
            name: 'select',
            items: {
              data: this.payingTypes,
              value: 'value',
              text: 'name',
            },
          },
          topLabel: true,
          labelShown: false,
          cols: 12,
          sm: 4,
          md: 4,
          lg: 4,
          placeholder: 'labels.paymentType',
        },

        {
          key: {
            parent: 'form',
            child: 'CompanyCategory',
          },
          rules: 'required',
          validationLabel: 'labels.memberType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.companyCategories,
              value: 'value',
              text: 'name',
            },
          },
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          placeholder: 'labels.memberType',
        },
        {
          key: {
            parent: 'form',
            child: 'AgencyId',
          },
          validationLabel: 'labels.agency',
          topLabel: true,
          labelShown: false,
          active: this.form.CompanyCategory == 1 ? true : false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.agencies,
              value: 'Id',
              text: 'Name',
              disableAutoSelection: true,
            },
          },
          willBeEliminated: false,
          cols: 12,
          sm: 6,
          md: 6,
          lg: 6,
          placeholder: 'labels.agency',
        },
        {
          key: {
            parent: 'form',
            child: 'AddCarrierLogoToLabel',
          },
          validationLabel: 'labels.isLabelShown',
          topLabel: true,
          light: false,
          labelShown: false,
          formType: {
            name: 'checkbox',
          },

          cols: 12,
          sm: 8,
          placeholder: 'labels.isLabelShown',
          classes: 'mb-5',
        },
      ];
    },
    editFormElement() {
      return [
        {
          key: {
            parent: 'editItem',
            child: 'PortalCustomerCode',
          },
          rules: 'required',
          validationLabel: 'labels.portalCustomerCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.portalCustomerCode',
        },
        {
          key: {
            parent: 'editItem',
            child: 'PortalSellerCode',
          },
          rules: 'required',
          validationLabel: 'labels.portalSellerCode',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'text',
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.portalSellerCode',
        },
        {
          key: {
            parent: 'editItem',
            child: 'PaymentTarget',
          },
          rules: 'required',
          validationLabel: 'labels.paymentTarget',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.paymentTargets,
              value: 'Id',
              text: 'Name',
            },
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.paymentTarget',
        },
        {
          key: {
            parent: 'editItem',
            child: 'PaymentTime',
          },
          rules: 'required',
          validationLabel: 'labels.payingType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.paymentTypes,
              value: 'value',
              text: 'name',
            },
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.payingType',
        },
        {
          key: {
            parent: 'editItem',
            child: 'PaymentType',
          },
          rules: 'required',
          validationLabel: 'labels.paymentType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',
            items: {
              data: this.payingTypes,
              value: 'value',
              text: 'name',
            },
          },
          lg: 6,
          cols: 6,
          placeholder: 'labels.paymentType',
        },
        {
          key: {
            parent: 'editItem',
            child: 'CompanyCategory',
          },
          rules: 'required',
          validationLabel: 'labels.memberType',
          topLabel: true,
          labelShown: false,
          formType: {
            name: 'select',

            items: {
              data: this.companyCategories,
              value: 'value',
              text: 'name',
            },
          },
          cols: 6,
          sm: 6,
          md: this.editItem.CompanyCategory == 1 ? 3 : 6,
          lg: this.editItem.CompanyCategory == 1 ? 3 : 6,
          placeholder: 'labels.memberType',
        },
        {
          key: {
            parent: 'editItem',
            child: 'AgencyId',
          },
          validationLabel: 'labels.agency',
          topLabel: true,
          labelShown: false,
          active: this.editItem.CompanyCategory == 1 ? true : false,
          formType: {
            name: 'multipleSelect',
            items: {
              data: this.agencies,
              value: 'Id',
              text: 'Name',
              disableAutoSelection: true,
            },
          },
          willBeEliminated: false,
          cols: 6,
          sm: 6,
          md: 3,
          lg: 3,
          placeholder: 'labels.agency',
        },
      ];
    },
  },
  methods: {
    setRowClass(item) {
      return item.PackageLoginPermission ? '' : 'passive-row';
    },
    activePortals() {
      if (this.selectedPortals.length == 0) {
        return;
      }
      let CompanyIds = this.selectedPortals.map((item) => item.Id);

      this.$store.state.loadingStatus = true;
      CompanyPackageService.activePortals(CompanyIds)
        .then((response) => {
          this.selectedPortals = [];
          this.getPortalUsers();
        })
        .catch((error) => {
          window.handleServiceError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    updateSelection(item) {
      if (this.selectedPortals.length + 1 == this.maxSelection) {
        window.showWarning(this.$t('labels.maxPortalWarning'));
      }
      if (!this.selectedPortals.includes(item)) {
        this.selectedPortals.push(item);
      } else {
        const index = this.selectedPortals.indexOf(item);
        if (index > -1) {
          this.selectedPortals.splice(index, 1);
        }
      }
    },

    fillAgencies() {
      this.$store.state.loadingStatus = true;
      CompanyServices.getAgencies()
        .then((response) => {
          this.agencies = response.data.Result;
        })
        .catch((error) => {
          window.handleServiceError(error);
        })
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    updatePortalUser() {
      this.$store.state.loadingStatus = true;
      let body = {
        CompanyId: this.editItem.Id,
        PortalCustomerCode: this.editItem.PortalCustomerCode,
        PortalSellerCode: this.editItem.PortalSellerCode,
        PaymentTime: this.editItem.PaymentTime,
        PaymentType: this.editItem.PaymentType,
        PaymentTarget: this.editItem.PaymentTarget,
        CompanyCategory: this.editItem.CompanyCategory,
        AgencyId:
          this.editItem.CompanyCategory == 1 ? this.editItem.AgencyId : null,
      };

      CompanyServices.updatePortalUser(body)
        .then((response) => {
          this.getPortalUsers();
          this.fillAgencies();
        })
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.editModal = false;
          this.editItem = {};
        });
    },
    edit(item) {
      this.editItem = {
        Id: item.Id,
        PortalCustomerCode: item.PortalCustomerCode,
        PortalSellerCode: item.PortalSellerCode,
        PaymentTime: item.PaymentTime,
        PaymentType: item.PaymentType,
        PaymentTarget: item.PaymentTarget,
        CompanyCategory: item.CompanyCategory,
      };
      if (item.CompanyCategory == 1) {
        this.editItem.AgencyId = item.AgencyId;
      }
      // this.editForm = {
      //   PortalCustomerCode: item.PortalCustomerCode,
      //   PortalSellerCode: item.PortalSellerCode,
      //   PortalCustomerType: item.PortalCustomerType,
      //   PaymentTime: item.PaymentTime,
      //   PaymentType: item.PaymentType,
      //   PaymentTarget: item.PaymentTarget,
      //   AddCarrierLogoToLabel: item.AddCarrierLogoToLabel,
      // };
      this.editModal = true;
    },
    resetEditModal() {
      this.editModal = false;
      this.editItem = {};
    },
    getSearchDebounced() {
      clearTimeout(this.debounceTimeout);
      this.debounceTimeout = setTimeout(() => {
        this.rowsPerPage = 15;
        this.page = 1;
        this.getPortalUsers();
      }, 300);
    },
    mergePortalUser() {
      let body = {
        CompanyMail: this.mail,
      };

      this.$store.state.loadingStatus = true;
      CompanyServices.connectPortalMail(body)
        .then((response) => {})
        .catch((error) => {})
        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.mergePortalUserDialog = false;
          this.resetMergeDialog();
        });
    },
    changeItemsPerPage() {
      this.page = 1;
      this.options.itemsPerPage = this.rowsPerPage;
      this.getPortalUsers();
    },
    paginationChangeHandler(pageNumber) {
      this.page = pageNumber;
      this.getPortalUsers();
    },
    clickedReceiptStatus(checked) {
      if (checked) {
        this.temporaryReceiptAddress = this.form.ReceiptAddress;
        this.form.ReceiptAddress = this.form.Address1;
      } else {
        this.form.ReceiptAddress = this.temporaryReceiptAddress;
      }
    },
    generatePassword() {
      this.btnLoading = true;
      AccountService.generatePassword()
        .then((response) => {
          this.form.Password = response.data.Result.Password;
          this.btnLoading = false;
        })
        .catch((error) => {
          window.handleServiceError(error);
          this.btnLoading = false;
        });
    },
    getPortalUsers() {
      this.$store.state.loadingStatus = true;
      PortalService.getPortalUsers(
        this.companyName,
        this.portalCustomerCode,
        this.portalSellerCode,
        this.portalCustomerType?.Id,
        this.page,
        this.rowsPerPage
      )
        .then((response) => {
          this.users = response.data.Result.PortalCompanies;
          this.maxSelection = response.data.Result.MaxPortal;
          this.totalItems = response.data.TotalItems;
          this.$store.state.loadingStatus = false;
        })
        .catch((error) => {
          window.handleServiceError(error);
          this.$store.state.loadingStatus = false;
        });
    },
    addPortalUser() {
      this.$store.state.loadingStatus = true;
      this.form.receiptStatus
        ? (this.form.ReceiptAddress = this.form.Address1)
        : null;

      if (this.form.CompanyCategory === 1 && !this.form.AgencyId) {
        this.form.AgencyId = null;
      }

      const { receiptStatus, ...result } = this.form;
      const body = Object.assign({}, result);

      PortalService.addPortalUser(body)
        .then((response) => {
          this.$store.state.loadingStatus = false;
          this.addPortalUserDialog = false;
          this.resetDialog();
          this.getPortalUsers();
          this.fillAgencies();
        })
        .catch((error) => {
          window.handleServiceError(error);
          this.$store.state.loadingStatus = false;
        });
    },
    loginAs(item) {
      this.$store.state.loadingStatus = true;
      let body = {
        UserId: item.OfficerId,
      };
      AccountService.portalLogin(body)
        .then((res) => {
          this.updatePortalUserId(this.$store.state.Id);
          this.portalLogin(res);
        })
        .catch((error) => {
          window.handleServiceError(error);
        })

        .finally(() => {
          this.$store.state.loadingStatus = false;
          this.$router.push({ name: 'home' });
        });
    },
    async getCountries() {
      await AddressService.getCounties()
        .then((response) => {
          this.countries = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    onChangeCountry() {
      this.$store.state.loadingStatus = true;
      AddressService.getCities(this.form.Country)
        .then((response) => {
          this.cities = response.data.Result;
        })
        .catch(window.showError)
        .finally(() => {
          this.$store.state.loadingStatus = false;
        });
    },
    resetDialog() {
      this.$refs.observer.reset();
      this.addPortalUserDialog = false;
      this.form = {
        AgencyId: null,
        PaymetTime: 0,
      };
    },

    resetMergeDialog() {
      this.$refs.observer.reset();
      this.mergePortalUserDialog = false;
      this.mail = null;
    },

    deleteCompany() {
      if (this.selectedCompany) {
        this.$store.state.loadingStatus = true;
        PortalService.deletePortalCompany({
          CompanyId: this.selectedCompany,
        })
          .then((response) => {
            window.showSuccess(
              this.$t('messages.successfulDelete', {
                value: this.$t('labels.user.singular'),
              })
            );
            this.getPortalUsers();
            this.fillAgencies();
          })
          .catch(window.showError)
          .finally(() => {
            this.$store.state.loadingStatus = false;
          });
      }
    },

    ...mapActions(['portalLogin', 'updatePortalUserId']),
  },
};
</script>
<style>
.passive-row {
  background-color: #f0f0f0;
  color: #a0a0a0;
}
</style>
